import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';
import { Apis } from '../../core/apis';
import { LoginRequest } from '../../models/reqres/admin-login.request';
import { TokenAndRefreshToken } from '../../models/tokenandrefreshtoken.model';
import { ProfileResponseModel } from '../../models/profile.model';

const API_LOGIN_URL = Apis.adminLogin;
const API_USER_ME_URL = Apis.me;
const API_APPLICATION_TO_LOGIN_WITH_URL = Apis.getApplicationToLoginWith;

@Injectable({
  providedIn: 'root',
})
export class AdminLoginService implements OnDestroy {
  callbackResponseForUser = new Subject();

  constructor(private http: HttpClient) {}

  login(data: LoginRequest): Observable<TokenAndRefreshToken> {
    return this.http.post<TokenAndRefreshToken>(API_LOGIN_URL, data);
  }

  user(): Observable<ProfileResponseModel> {
    return this.http.post<any>(API_USER_ME_URL, {
      userId: '',
      apiKey: '',
    });
  }

  getApplicationToLoginWith(
    applicationCode: any,
    emailId: any
  ): Observable<any> {
    return this.http.get<any>(
      `${API_APPLICATION_TO_LOGIN_WITH_URL}/${applicationCode}/${emailId}`
    );
  }

  updateUser(val: any) {
    this.callbackResponseForUser.next(val);
  }

  ngOnDestroy() {}
}
