import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Apis } from '../../core/apis';
import { Subject } from 'rxjs';
import { GenericListResponse } from '../../models/generic-list-response';
import { AccessControlCategory } from '../../models/access-control.model';

const API_BY_CATEGORY_URL = Apis.byCategory;
const API_ACCESS_CONTROL_URL = Apis.accessControl;

@Injectable({
  providedIn: 'root',
})
export class SideBarService implements OnDestroy {
  constructor(private http: HttpClient) {}
  callbackResponse = new Subject();
  getbyCategory(): Observable<GenericListResponse<AccessControlCategory>> {
    return this.http.get<GenericListResponse<AccessControlCategory>>(
      API_BY_CATEGORY_URL
    );
  }

  getAccessControl(): Observable<any> {
    return this.http.get<any>(API_ACCESS_CONTROL_URL);
  }

  updateLocalStorage(val: any) {
    this.callbackResponse.next(val);
  }
  ngOnDestroy() {}
}
