import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from './services/local-storage.service';
import { KeywordsAndConstants } from '../core/keywords-and-constants';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  currentRoute!: string;
  needToChangePassword: boolean = false;

  constructor(
    private constants: KeywordsAndConstants,
    private router: Router,
    private _localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) public platformId: Object
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem(this.constants.LocalStorageToken);
      const url = route.routeConfig?.path;
      if (token) {
        return !this.needToChangePassword;
      } else {
        if (
          url == 'user/login' ||
          url == 'password/forgot-password' ||
          url == 'otp-verification' ||
          url == 'twoFactor' ||
          url == 'liveness-check'
        ) {
          return true;
        }
      }
      this._localStorageService.logout();
    }
    return false;
  }
}
