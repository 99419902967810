import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { KeywordsAndConstants } from '../../core/keywords-and-constants';

@Injectable({
  providedIn: 'root',
})
export class TokenCallbackService {
  constructor(
    private constants: KeywordsAndConstants,
    private router: Router
  ) {}

  readyToCallSideBarApis = false;
  refreshOnProgress: boolean = false;

  refreshTokenForPageIsDone(withDelay: boolean = false) {
    let delayTime = 0;
    if (withDelay) {
      delayTime = 10;
    }
    setTimeout(() => {
      this.readyToCallSideBarApis = true;
    }, delayTime);
  }

  observeForCallingApisInSideBar(): Observable<boolean> {
    return timer(0, 1000).pipe(
      map(() => {
        return this.readyToCallSideBarApis;
      })
    );
  }
}
