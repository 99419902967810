import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { DeviceDetectorService } from 'ngx-device-detector';
import { KeywordsAndConstants } from '../../core/keywords-and-constants';
import { AccessControlService } from './access-control.service';
import { isPlatformBrowser } from '@angular/common';

var myItem = uuidv4();

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  deviceInfo: any;
  deviceName: String = '';
  uniqueIdentifierId: any;
  deviceUserAgent: any;
  platform: string = '';
  backUpToken: string = '';
  backUpRefreshToken: string = '';
  constructor(
    public constants: KeywordsAndConstants,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private _controlService: AccessControlService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        localStorage.getItem('uniqueIdentifierId') == '' ||
        localStorage.getItem('uniqueIdentifierId') == null ||
        localStorage.getItem('uniqueIdentifierId') == undefined
      ) {
        localStorage.setItem('uniqueIdentifierId', myItem);
      }
    }
  }

  getToken() {
    try {
      if (
        localStorage.getItem(this.constants.LocalStorageToken) &&
        this.backUpToken != ''
      ) {
        if (
          this.backUpToken !=
          localStorage.getItem(this.constants.LocalStorageToken)
        ) {
   
          return this.backUpToken;
        }
      }
      const token = localStorage.getItem(this.constants.LocalStorageToken);
      if (!token) {
        return undefined;
      }
      const authToken = JSON.parse(token);
      return authToken;
    } catch (error) {
      return error;
    }
  }

  getRefreshToken() {
    try {
      if (
        localStorage.getItem(this.constants.LocalStorageToken) &&
        this.backUpRefreshToken != ''
      ) {
        if (
          this.backUpRefreshToken !=
          localStorage.getItem(this.constants.LocalStorageRefreshToken)
        ) {
         
          return this.backUpRefreshToken;
        }
      }
      const refreshtoken = localStorage.getItem(
        this.constants.LocalStorageRefreshToken
      );
      if (!refreshtoken) {
        return undefined;
      }
      const authrefreshToken = JSON.parse(refreshtoken);
      return authrefreshToken;
    } catch (error) {
      return error;
    }
  }

  deviceInformation() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (isPlatformBrowser(this.platformId)) {
      this.uniqueIdentifierId = localStorage.getItem('uniqueIdentifierId');
    }
    this.deviceUserAgent = this.deviceInfo.userAgent;
    switch (this.deviceInfo.deviceType) {
      case 'desktop': {
        if (this.deviceInfo.os == 'Windows') {
          this.deviceInfo.browser = 'WINDOWS_DESKTOP';
          this.platform = 'WINDOWS_DESKTOP';
        } else {
          this.deviceInfo.browser = 'MAC';
          this.platform = 'MAC';
        }
        break;
      }
      case 'mobile': {
        if (this.deviceInfo.os == 'Android') {
          this.deviceInfo.browser = 'ANDROID';
          this.platform = 'ANDROID';
        } else {
          this.deviceInfo.browser = 'IOS';
          this.platform = 'IOS';
        }
        break;
      }
      default: {
      }
    }
  }

  logout() {
    let isMasterUserLoggedIn =
      localStorage.getItem(this.constants.isLoginType) == 'MASTER_USER';
    let isUserLoggedIn =
      localStorage.getItem(this.constants.isLoginType) == 'USER';

    let isSubUserLoggedIn =
      localStorage.getItem(this.constants.isLoginType) == 'SUB_MASTER_USER';
    let existingUniqueId = localStorage.getItem('uniqueIdentifierId');
    this.backUpToken = '';
    this.backUpRefreshToken = '';
    this._controlService.CategoryMethod = [];
    this._controlService.byCategoryMethod = null;
    localStorage.clear();
    localStorage.setItem('uniqueIdentifierId', existingUniqueId!!);
    if (isMasterUserLoggedIn) {
      this.router.navigate(['/master-user/login']);
      1;
    } else if (isUserLoggedIn) {
      this.router.navigate(['/user/login']);
    } else if (isSubUserLoggedIn) {
      this.router.navigate(['/sub-user/login']);
    } else {
      this.router.navigate(['']);
    }
  }
}
